import api from "@/base/utils/request";


//获取商会介绍列表
export const maIntroList = data => {
  return api({
    url: "/admin/cyc/Settings/IntroduceIndex",
    method: "post",
    data
  });
};
//获取商会介绍
export const getCycIntroduce = data => {
  return api({
    url: "/admin/cyc/Settings/getCycIntroduceConfig",
    method: "post",
    data
  });
};
//获取商会历程
export const getCycCourse = data => {
  return api({
    url: "/admin/cyc/Settings/getCycCourseConfig",
    method: "post",
    data
  });
};
//获取商会章程
export const getCycRegulations= data => {
  return api({
    url: "/admin/cyc/Settings/getCycRegulationsConfig",
    method: "post",
    data
  });
};
//设置商会介绍
export const setCycIntroduce = data => {
  return api({
    url: "/admin/cyc/Settings/setCycIntroduceConfig",
    method: "post",
    data
  });
};
//设置商会历程
export const setCycCourse = data => {
  return api({
    url: "/admin/cyc/Settings/setCycCourseConfig",
    method: "post",
    data
  });
};
//设置商会章程
export const setCycRegulations= data => {
  return api({
    url: "/admin/cyc/Settings/setCycRegulationsConfig",
    method: "post",
    data
  });
};