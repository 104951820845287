<template>
  <div class="phone-outline">
    <!-- 纯样式容器 -->
    <div class="phone">
      <div class="screen">
        <div class="page">
          <div class="head">
            <div class="sound"></div>
            <div class="camera"></div>
            <div class="left-arc">
              <div class="left-circle"></div>
            </div>
            <div class="right-arc">
              <div class="right-circle"></div>
            </div>
          </div>
          <div class="time">{{getTime(new Date())}}</div>
          <div class="signal">
            <span class="i" v-for="i in 4" :style="{height: i + 3 + 'px'}"></span>
          </div>
          <div class="nav" v-show="showNav">
            <div class="capsule">
              <i class="el-icon-arrow-left"></i>
              <el-divider direction="vertical"></el-divider>
              <i class="el-icon-s-home"></i>
            </div>
            <div class="title">{{title}}</div>
            <div class="capsule">
              <i class="el-icon-more"></i>
              <el-divider direction="vertical"></el-divider>
              <i class="el-icon-s-help"></i>
            </div>
          </div>
          <el-scrollbar class="slot-content" :style="{height: showNav ? 'calc(100% - 27px - 44px)' : 'calc(100% - 27px)'}">
            <slot></slot>
          </el-scrollbar>
        </div>
        <div class="bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Phone",
    props: {
      title: {
        type: String,
        default: ''
      },
      showNav: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      getTime(date) {
        let now = date;
        let _hour = (10 > now.getHours()) ? '0' + now.getHours() : now.getHours();
        let _minute = (10 > now.getMinutes()) ? '0' + now.getMinutes() : now.getMinutes();
        return `${_hour}:${_minute}`
      }
    }
  }
</script>

<style lang="scss">
  .phone-outline {
    background: #303030;
    box-shadow: 0 0 0 5px rgba(80, 80, 80, .8) inset;
    border-radius: 55px;
    padding: 7px;
    position: relative;
    width: 380px;

    .phone {
      padding: 15px;
      border-radius: 50px;
      border: 1px solid black;
      background: #222;
      box-shadow: 0 0 0 7px rgba(68, 68, 68, .3);
    }

    .screen {
      border-radius: 30px;
      background: #fff;
      position: relative;
      overflow: hidden;

      .page {
        height: 770px;
        min-height: 770px;
        position: relative;

        .time {
          position: absolute;
          top: 10px;
          left: 32px;
          font-size: 10px;
        }

        .head {
          width: 56.28%;
          height: 27px;
          margin: 0 auto;
          position: relative;
          background-color: #222;
          border-radius: 0 0 20px 20px;

          .sound {
            width: 48px;
            height: 6px;
            border-radius: 15px;
            background-color: #555;
            position: absolute;
            left: calc(50% - 48px / 2);
            top: calc(50% - 5px);
          }

          .camera {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #2c5487;
            position: absolute;
            left: calc(50% + 34px);
            top: calc(50% - 5px);
          }

          .left-arc, .right-arc {
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #222;
          }

          .left-circle, .right-circle {
            background-color: white;
            width: 100%;
            height: 100%;
          }

          .left-arc {
            left: -10px;

            .left-circle {
              border-radius: 0 5px 0 0;
            }
          }

          .right-arc {
            right: -10px;

            .right-circle {
              border-radius: 5px 0 0 0;
            }
          }
        }

        .nav {
          height: 44px;
          line-height: 44px;
          padding: 0 6px;
          width: 100%;
          /*box-shadow: 0 10px 12px 1px #f5f6f7;*/
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .capsule {
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-radius: 16px;
            border: 1px solid #f1f1f1;
            height: 32px;
            width: 87px;
            padding: 0 12px;
            font-size: 18px;
          }
        }

        ::v-deep .slot-content {
          width: 100%;
          height: calc(100% - 27px - 44px);
          overflow: hidden scroll;

          .is-horizontal {
            display: none;
          }
        }

        .signal {
          position: absolute;
          top: 12px;
          right: 32px;
          display: flex;
          align-items: flex-end;

          .i {
            width: 2px;
            background-color: #111;
            margin: 0 1px;
          }
        }
      }

      .bar {
        width: 30%;
        height: 6px;
        border-radius: 15px;
        background: #000;
        position: absolute;
        bottom: 8px;
        left: 50%;
        margin-left: -15%;
      }
    }
  }
</style>

