<template>
  <el-dialog :visible.sync="show" title="预览" width="300px" class="dialog-vertical">
    <div class="content">
      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIoAAACKAQMAAABPSUAnAAAABlBMVEX///8AAABVwtN+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADG0lEQVRIia3WTaqkMBAA4JJAu2nMBQSvYO90E68SyDUCCXgNwauYje7sKwS8gO5skNSUPW/1Og0OTMDNB0qS+hPgPy6OPm1dezPb8kTe4hGlO8gxD0ywzIBxLeRxCnI6XIvLrIN5tk33jQK8jrR/9INK/olU0k+9w+exssdXAnlialcFemY/W/1NHJe6G1XdzepBz99jfxAAS1s6nR20s9nPjV2i3bysW1LaF0dhyi80sFu+Q9MDzMb5WUeJNYvI3ZEsYDI5S3dcpfvUDsWEQq5mZlkxQ5wqJeTN7BrUqrI8k1HiVT5SOKY2FIKu8p05EXr5XVYgZAaIpQnyKsHUj6qiw5Y6QbT8iFPqBUOsMVNCDcr5KLFQ7LLuApQFyIaCcpWgMqN6HQ3FcOsafH/+k+7Yp70oRLEypHQr8yiBQ6ohxO6kUgWIU2IrlviBJctgkuMM2jViQb9o71R8indCljpKd6QyFnRO+oZG/w7tJ/GbevVg6hbtQOWc2KsEdbub+hjNIFe2svcVftJ9wiqvNOKGTZfgZqMEgWoo5I1dJfUc3xxxEirFtMNuWzKFy1nu14iP5wUWaDcPVB0ijxLtS5gANYZipXaANkqsts4Lan1QUNLpM9oxEroC150vcJ8V3F6lu+uEuRU745YirTMdJQhqlzd1Y6uczXPZjijxyb5a51N79vFtOcv9Gt2FamxtG+SWDpO/kzxCTe+W3dzkrLc26QeIEm+WCgaZIu+pS8unjxKtxlay7pOFUrR72KvEUw/FdNwU5TXDHr8QwAsrGbSjcYJH0FFi1Mg86NGAogTXD3+VKO9HGGlfrn+01Kp0lPioz25CQ0JzD+os5AixKp+OCQdT6ieWVMxRujscipFinuUDzMV7SF8iHiDk9FBXLXNchI4SgKLOKmmwolBgVvhCbCwo9+2zc20phbxKNGvPdpV2s5lpxgiI0vmLQU09sa5v2sb/TPcPogFCveSFiLPa8BwnUVKIggISTEZVN/8L7bLpBX2vlIjvEo0QyMlPPeigaL4vP38dv4n+rFxLvYQ9D7SiyOAq/cf1BxTCCO+b7SjPAAAAAElFTkSuQmCC"
        alt="二维码"
      />
      <p class="info">扫码预览</p>
    </div>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    //是否显示
    open: Boolean,
    //二维码图片地址
    imgSrc: String,
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-vertical {
  .content {
    text-align: center;
    img {
      width: 130px;
      height: 130px;
    }
    .info {
      padding: 12px;
      color: #303133;
    }
  }
}
</style>
