import request from '@/base/utils/request';
import api from "@/base/utils/request";
import store from "@/base/store";
import { Message } from 'element-ui';

//上传文件api
export const uploadFile = data => {
  //获取上传配置
  const reqConfig = store.getters.uploadConfig;
  if (!reqConfig) {
    Message.warning("无法上传，获取不到上传配置，请检查是否获取到请求配置数据！");
    return;
  };
  //请求配置文件
  let config = {
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  //判断是否要使用上传token
  if (reqConfig.upload_token) {
    config.headers["upload_token"] = reqConfig.upload_token;
  };
  //判断上传链接是不是带http
  if (/^http/.test(reqConfig.url)) {
    config.url = "";
    config.baseUrl = reqConfig.url;
  } else {
    config.url = reqConfig.url;
  }
  console.log(reqConfig)
  return request(config);
};

// 获取手机上传二维码
export const scanCode = data => {
  return api({
    url: "/admin/admin/media/phoneUploadQrCode",
    method: "post",
    data
  });
};
