<template>
  <div class="ma-config reset-btn-page" v-loading="show_loading">
    <!--    <div class="head">-->
    <!--      <h2 class="title">关于{{pageTitle}}</h2>-->
    <!--    </div>-->
    <div class="content">
      <div class="left">
        <el-form :model="form" :rules="rules"
                 ref="ma-form"
                 label-position="top" size="medium">
          <el-form-item label="标题" prop="title">
            <el-input size="medium" v-model="form.title" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="介绍" prop="textarea">
            <!-- <el-input v-model="form.textarea" type="textarea" :rows="28" placeholder="请输入内容"></el-input>-->
            <tinymce id="tinymce-editor" @choose="handleToolBarClick" v-model="form.textarea" :height="550"></tinymce>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <div class="el-form-item__label">预览：</div>
        <Phone :title="form.title">
          <div class="phone-text" v-html="myContent"></div>
        </Phone>
      </div>
    </div>
    <!--    <image-select v-model="showImageList" :max="max" @handleChoose="updateImages"></image-select>-->
    <!--    <video-select v-model="showVideoList" :max="max" @choose="updateVideos"></video-select>-->
    <!--    <audio-select v-model="showAudioList" :max="max" @choose="updateAudios"></audio-select>-->
    <media-selector v-model="showImageList" multiple @select="updateImages"></media-selector>
    <media-selector v-model="showVideoList" type="video" multiple @select="updateVideos"></media-selector>
    <media-selector v-model="showAudioList" type="audio" multiple @select="updateAudios"></media-selector>
    <!-- 预览弹窗 -->
    <preview-dialog v-model="openPreviewDialog" img-src="xxxx"/>
    <fixed-action-bar :z-index="2">
      <el-button type="primary" :loading="saveLoading" @click="onSave">保存</el-button>
      <!-- <el-button :loading="previewLoading" @click="onPreview">预览</el-button>-->
      <el-button @click="onCancel">取消</el-button>
    </fixed-action-bar>
  </div>
</template>
<script>
  import PreviewDialog from "../components/config/PreviewDialog";
  import Tinymce from "@/base/components/Editor/Tinymce";
  // import ImageSelect from "@/modules/common/components/ImageSelect";
  // import VideoSelect from "../../common/components/VideoSelect";
  // import AudioSelect from "../../common/components/AudioSelect";
  import {
    getCycIntroduce,
    getCycCourse,
    getCycRegulations,
    setCycIntroduce,
    setCycCourse,
    setCycRegulations,
  } from "../api/ma-intro";
  import Phone from "../../../base/components/Preview/Phone";
  import FixedActionBar from "../../../base/layout/FixedActionBar";
  import MediaSelector from "../../common/components/MediaSelector";

  export default {
    data() {
      let validateText = (rule, value, callback) => {
        if (this.form.textarea === '') {
          callback(new Error('请输入介绍'));
        } else {
          callback();
        }
      };
      return {
        form: {
          title: "", //标题
          textarea: "", //富文本内容
        },
        rules: {
          title: [
            {required: true, message: "请输入标题"}
          ],
          textarea: [
            {validator: validateText, trigger: 'blur'},
            {required: true, message: "请输入介绍", trigger: "blur"}
          ]
        },
        saveLoading: false, //保存中
        previewLoading: false, //预览生成中
        openPreviewDialog: false, //显示预览弹窗
        //要设置的内容名称
        configName: "",
        showImageList: false,
        showVideoList: false,
        showAudioList: false,
        pageTitle: "商会介绍",
        show_loading: false,
      };
    },
    computed: {
      myContent: function () {
        if (!this.form || !this.form.textarea) return ''
        return this.form.textarea.replace(/\<img/gi, '<img class="max-width"')
          .replace(/<video/gi, '<video class="max-width"')
          .replace(/<section/gi, '<section class="overflow-x"')
          .replace(/<p/gi, '<p class="overflow-x"')
      },
    },
    created() {
      this.configName = this.$route.params.name;
      this.getCycDetail();
    },
    methods: {
      getCycDetail() {
        this.show_loading = true;
        if (this.configName === "CycIntroduce") {
          this.pageTitle = "商会介绍";
          getCycIntroduce().then((res) => {
            this.form.title = res.data.title;
            this.form.textarea = res.data.content;
            this.show_loading = false;
          });
        } else if (this.configName === "CycCourse") {
          this.pageTitle = "商会发展历程";
          getCycCourse().then((res) => {
            this.form.title = res.data.title;
            this.form.textarea = res.data.content;
            this.show_loading = false;
          });
        } else if (this.configName === "CycRegulations") {
          this.pageTitle = "商会章程";
          getCycRegulations().then((res) => {
            this.form.title = res.data.title;
            this.form.textarea = res.data.content;
            this.show_loading = false;
          });
        }
      },
      updateImages(e) {
        e.forEach((item) => {
          window.tinymce.execCommand(
            "mceReplaceContent",
            false,
            `<img src="${item.url}"/>`
          );
        });
        this.showImageList = false;
      },
      updateVideos(e) {
        e.forEach((item) => {
          window.tinymce.execCommand(
            "mceReplaceContent",
            false,
            `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          );
        });
        this.showVideoList = false;
      },
      updateAudios(e) {
        e.forEach((item) => {
          window.tinymce.execCommand(
            "mceReplaceContent",
            false,
            `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
          );
        });
        this.showAudioList = false;
      },
      //选择图片
      chooseImages() {
        this.showImageList = true;
      },
      //选择视频
      chooseVideos() {
        this.showVideoList = true;
      },
      //选择音频
      chooseAudios() {
        this.showAudioList = true;
      },
      // 富文本框点击图片，视频，音频返回事件
      handleToolBarClick(e) {
        switch (e.type) {
          case "image":
            this.chooseImages();
            break;
          case "video":
            this.chooseVideos();
            break;
          case "audio":
            this.chooseAudios();
            break;
        }
      },
      //保存
      onSave() {
        // if (this.form.title === "" || this.form.textarea === "") {
        //   this.$message({
        //     message: "标题和介绍内容不能为空",
        //     type: "warning",
        //   });
        //   return;
        // }
        this.$refs['ma-form'].validate((valid) => {
          if (valid) {
            this.doSave();
          } else {
            return false;
          }
        });
      },
      doSave() {
        let data = {
          title: this.form.title,
          content: this.form.textarea,
          update_time: Date.parse(new Date()) / 1000,
        };
        if (this.configName === "CycIntroduce") {
          setCycIntroduce({config: data}).then((res) => {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getCycDetail();
          });
        } else if (this.configName === "CycCourse") {
          setCycCourse({config: data}).then((res) => {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getCycDetail();
          });
        } else if (this.configName === "CycRegulations") {
          setCycRegulations({config: data}).then((res) => {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getCycDetail();
          });
        }
      },
      //预览
      onPreview() {
        this.previewLoading = true;
        setTimeout(() => {
          this.openPreviewDialog = true;
          this.previewLoading = false;
        }, 1000);
      },
      //取消
      onCancel() {
        this.$router.go(-1);
      },
    },
    components: {
      MediaSelector,
      FixedActionBar,
      Phone,
      PreviewDialog,
      Tinymce,
      // ImageSelect,
      // VideoSelect,
      // AudioSelect,
    },
  };
</script>
<style lang="scss" scoped>
  .ma-config {
    .head {
      margin-bottom: 20px;

      .title {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
      }
    }

    .content {
      display: flex;

      .left {
        flex: 1;

        .el-form {
          max-width: 800px;
        }
      }

      .right {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .phone-text {
          padding-left: 12px;
          padding-right: 12px;
          /* 针对textarea标签输入的文本 */
          /*white-space: pre-wrap;*/
          /*word-wrap: break-word;*/
          /*word-break: break-all;*/
        }
      }

      .item {
        & + .item {
          margin-top: 15px;
        }

        .label {
          font-size: 16px;
          line-height: 22px;
          color: rgba(33, 33, 33, 1);
          margin-bottom: 8px;

          &::before {
            content: "*";
            color: #f56c6c;
            margin-right: 4px;
          }
        }
      }
    }
  }
</style>
